export const environment = {
  production: false,
  apiUrl: 'https://xspectarapi-beta.azurewebsites.net/api/tenant/CDCD99BB-CEFD-4527-A6BD-BD1F84AAAFED/',
  providerApiUrl: 'https://beta-api.xspectar.com/api/',
  bitmapTackerHub: 'https://verify.bitcoin.fuelstack.dev:5001/',
  hostName: 'xspectar.com',
  
  xSpectarVerseApiUrl: 'https://xspectarverse-beta.azurewebsites.net/api/',

  landImageApiUrl: 'https://xspectarverse-dev-landimage-api-euhvbnhsfjbvesaq.ukwest-01.azurewebsites.net/',//'http://localhost:3000/',
  xSpectarVerseLandGuid: '069BA694-1273-4D00-A169-AC3BF360621A',

  videoUrl: 'https://xspectarnfts.blob.core.windows.net/assets/video/intro_',
  sceneRootUrl: 'https://xspectarnfts.blob.core.windows.net/assets/geom/',
  marketingSite: 'https://beta.xspectar.com/',
  tenantGuid: 'CDCD99BB-CEFD-4527-A6BD-BD1F84AAAFED',
  xummProviderGuid: '363B0511-C6AD-4F25-A6F9-3A9E4B03BD80'.toLocaleLowerCase(),
  hashPackProviderGuid: '1F454F19-BA94-402E-AA81-23D4444CD267'.toLocaleLowerCase(),
  genericEvmWalletProviderGuid: '7306a19b-b425-4fef-a504-0bc5543386eb'.toLocaleLowerCase(),
  genericBtcWalletProviderGuid: '5B60B8E1-F6EB-4B78-8F90-D33E115E8587'.toLocaleLowerCase(),
  genericXtcWalletProviderGuid: 'FB3C1B73-808F-4FD0-BB05-7DC4CFCF88CC'.toLocaleLowerCase(),
  
  xUserProviderGuid: 'A44561B9-F81B-402E-AD29-E7EA596B92ED'.toLocaleLowerCase(),
  stripeProviderGuid: '5249ECA6-DB63-47F2-AF43-FCA285C3552B'.toLocaleLowerCase(),
  xrplBlockchainProviderGuid: '619AD4CA-522B-450F-BB7A-8D1446827624'.toLocaleLowerCase(),
  ethereumProviderGuid : '739806f7-6516-4e5c-94f6-4aa8b40d7d3f',
  metaMaskProviderGuid : 'd38ca5b9-845e-444e-889f-7badc6dcccd5',
  solanaProviderGuid : '4e7608c9-02b0-4fbd-9685-bb3d5f3ce5d9',
  phantomProviderGuid : '18184ef9-d8b9-47e0-9c1d-e190c7a58fba',
  hiroWalletProviderGuid : '92562BD8-8443-419B-A333-B7189E0FBD67'.toLocaleLowerCase(),
  uniSatProviderGuid : '9B630E9B-8111-42BF-88D7-41F59FD43D44'.toLocaleLowerCase(),
  flareProviderGuid: 'E9C5C303-9B33-41EB-B26F-5E72DE5502A3'.toLocaleLowerCase(),
  songbirdProviderGuid: 'D25ECB86-40FD-4E54-90A6-537135192198'.toLocaleLowerCase(),
  bscProviderGuid: '00b830f5-ff06-48c5-a7a6-67e0b2f11c28'.toLocaleLowerCase(),

  xrplPaymentInstrumentIssuerGuid: 'F5730591-203F-4002-BA56-85D144EEB3EC'.toLocaleLowerCase(),
  hederaPaymentInstrumentIssuerGuid: '187450C5-36ED-4E15-AB5C-67BF8CDA487E'.toLocaleLowerCase(),
  xdcPaymentInstrumentIssuerGuid: '079819C6-804E-4905-ACCA-D1648724624C'.toLocaleLowerCase(),
  ethereumPaymentInstrumentIssuerGuid: '711B4F3A-E9C3-459E-A6BA-7B52EF45C755'.toLocaleLowerCase(),
  stripePaymentInstrumentIssuerGuid: 'BDF47280-B0F7-4838-BF30-8FE90B2C06C3'.toLocaleLowerCase(),
  stripeSalePaymentInstrumentGuid: '22533EFD-5857-4E90-AC25-FC1C798AB606'.toLocaleLowerCase(),
  bitcoinPaymentInstrumentIssuerGuid: '4F4BB61B-102B-4127-82E4-6585757AA2AB'.toLocaleLowerCase(),
  flarePaymentInstrumentIssuerGuid: 'FB92E223-89EE-477A-AA47-470EDF8B3B60'.toLocaleLowerCase(),
  songbirdPaymentInstrumentIssuerGuid: '731AB0FA-99F2-4482-8FBC-E4BF89CEC1CF'.toLocaleLowerCase(),
  bscPaymentInstrumentIssuerGuid: '76b6174b-f278-40b4-a6bd-ed0c24d2ac5a'.toLocaleLowerCase(),
  
  hederaProviderGuid : '1e12027a-6eb1-4fa1-8be4-68edda66cc04',
  xdcProviderGuid: '9C7039B3-B542-44C3-8EA0-1FAD3641D9AF'.toLocaleLowerCase(),
  btcProviderGuid : '3ADC10DE-C3B3-4703-ADBA-A50669C3BD95'.toLocaleLowerCase(),

  ethereumChainId : 1,
  xdcChainId : 50,
  flareChainId : 14,
  songbirdChainId : 19,
  bscChainId : 56,
  
  hashpackAppMetadata : {
    name: "dev-xspectar",
    description: "Xspectar-Dev",
    icons: ["https://account.xspectar.com/assets/v2/images/logo.svg"],
    url: "https://account-dev.xspectar.com"
  },
  hashpackNetwork : 'test',
  hashpackProjectId : "fc69207f737a611747e09868b3f77be4",

  xrpCurrencyGuid: '19963576-eb78-4e54-a48d-4e210b517836',
  xSpectarCurrencyGuid: '82e40ca4-9511-4cd9-8d32-1fa406b70cf0',
  aTMCurrencyGuid: '86f35048-c56e-4dfe-96b2-15f3ac5e4e85',
  usdCurrencyGuid: '657ca3d0-0b71-4d5b-acf8-126b00b616cf',
  hbarCurrencyGuid: 'D1696CE8-D092-44DD-9515-901D22161282'.toLocaleLowerCase(),
  xdcCurrencyGuid: 'A466CB99-CB58-42B1-A486-F60563350AC5'.toLocaleLowerCase(),
  flareCurrencyGuid: '465F0464-A387-4199-8122-281D7663321A'.toLocaleLowerCase(),
  songbirdCurrencyGuid: '0BFA1322-547A-4230-8D93-9A1D9CE97CA8'.toLocaleLowerCase(),
  ethereumCurrencyGuid: '58589773-0DBD-4462-9ADD-34359983B857'.toLocaleLowerCase(),

  
  agentListingGuid: '40009863-519a-4bda-8dcf-4d94cde3ee67',
  unlockListingGuid: '849fc0a9-e2e6-4cc4-92c8-6e5ded16e407',
  agent444ListingGuid: '604be37f-d4ff-47af-9779-d74666ca56bb',
  unlockAssetSeriesGuid: '90552bd2-a09f-46fa-8021-07c5b9175111',
  agentAssetSeriesGuid: '52e1a144-0f4c-4518-b292-96c356d4e236',
  the444at444AssetSeriesGuid: 'a63d5797-4dc6-428a-b987-66b1c89fac5e',
  vaultAssetCollectionGuid: '3fca3f9e-b376-42fb-9400-5e56468627b0',
  giveawaysAssetCollectionGuid: '9196170f-2195-4958-8388-8d319ce30285',
  landSalePlotsAssetCollectionGuid: '88de15cd-fce6-49fd-a19c-ac71844d6790',
  the88agentAssetSeriesGuid : 'b695f8cf-608d-4f7c-817c-d1e2910d2be8',
  freeKangaAssetSeriesGuid: 'f90dc622-798e-442f-abc7-8234ace4c7f5',
  transactionStatusRefundedGuid : 'd99099b5-1cfa-4454-be91-cb99ab61f4dd',
  

  diamondLandSeriesGuid: '46b6b221-1bbc-4745-a6ac-863aa86f7b71',
  platinumLandSeriesGuid: '2a6562f6-ce46-4123-8bd0-349d8927b4c6',
  goldLandSeriesGuid: 'ede16e32-47f8-41f8-a82b-5ff70cacc54e',
  silverLandSeriesGuid: '12ef0303-c54c-413d-8e23-97530f769da6',
  bronzeLandSeriesGuid: '4883aece-c9d0-4246-a707-389bdd3ee015',
  freeLandSeriesFromNftPreMintGuid: '7ec17d91-f6dc-40c2-8331-0affe5cd06a1',
  freeLandSeriesFromPackagePreMintGuid: '9c76614f-6747-429b-bba5-4c40133c167f',
  finalLandSeriesFromPackagePreMintGuid: '31DC7A90-1F07-45E4-A916-A8E8C5ACFD33'.toLocaleLowerCase(),
  landParcelSeriesGuid:'2A3B7724-C42A-4CA7-90B5-57DF4FBF4FDF'.toLocaleLowerCase(),
  
  assetTyprewardAssetTypeGuid: 'CEBFF9B7-F891-42EA-9F06-A9197ADF8C5B'.toLocaleLowerCase(),
  featureUnlockAssetTypeGuid : '3AE302CF-AEDB-493B-B693-764E78C17707'.toLocaleLowerCase(),
  rewardAssetTypeGuid: 'CEBFF9B7-F891-42EA-9F06-A9197ADF8C5B'.toLocaleLowerCase(),
  tokenStakeAssetTypeGuid: 'A487E7BD-13F0-4367-BFD0-D5EE90ACDBC3'.toLocaleLowerCase(),
  mintedUnclaimedAssetTypeGuid: 'D45DA9F5-6E7B-4CD2-89E1-0415BC1554E4'.toLocaleLowerCase(),
  mintedAssetTypeGuid: 'E1257F7F-6D51-4590-BD46-C40281E805F5'.toLocaleLowerCase(),

  activeAssetStatusGuid : '6e8776ba-1a72-4f56-b743-0881b47b73d0',
  refundedAssetStatusGuid : '30e7635a-5bda-4767-9691-629cc8bbdc35',
  soldAssetStatusGuid : '73cdf89f-7669-43b3-9708-f5ff63eeaf97',
  transferedAssetStatusGuid : '84e96722-6cec-489e-b1e9-3d9740919569',
  blockedAssetStatusGuid : '7138a722-d5cb-4adf-92ce-80ab085f23b2',
  redeemedAssetStatusGuid : '519793bc-9d46-4646-9885-f99465b02d0a',
  redeemingAssetStatusGuid : '8409a7f2-16a3-4983-91ba-09ba5dd7b834',
  redeemFaileAssetStatusGuid : '50bc9d06-d25c-4d9f-bc52-8518d1c5962c',

  securityHubTypeGuid : 'ea23ee8a-476f-44dd-86a6-5805e8975d27',
  notiforcationsHubTypeGuid : '5622bfcd-3e26-48eb-9715-066296707906',

  sentryDsn: 'https://3380435def56411c86e07b27a042a08f@o4504026656538624.ingest.sentry.io/4504026660012032',

  xSpectarIssuerWallet : "ra59pDJcuqKJcQws7Xpuu1S8UYKmKnpUkW",
  dsCollectionIssuerWallet : "rJcuzN4WAwCZMxxhff5YmX2r4WjwLK4ujT",
  xSpectar8888Name : "XSpectar Agents",
  xSpectar88Name : "xSpectar Agent 88",
  dsCollectionName : "The Ds Collection",

  stripePublicKey: 'pk_test_51OITERKm8FouOc4HTSwSHTqnrxRmn5TX8cPghXwLNCDMuC4QOlGuxmeEwlLhETYQ4bx2QX52KhehIDVr7KOttl2f00gz9BubDx'


};
